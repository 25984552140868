<template>
  <div class="box">
    <h6 class="title is-6">Account Status</h6>

    <div class="content" v-if="user.subType == 'trial'">
      <p>
        Your trial ends <b>{{ subEndPretty }}</b> and you have
        <b>{{ user.freeRequests }}</b> free API requests remaining. <br />
        <a @click="upgrade()">Upgrade</a> to get unlimited validation and lookup requests!
      </p>
      <div class="buttons">
        <a class="button is-success" @click="upgrade()"
          ><span class="icon-text">
            <span class="icon">
              <i class="fas fa-arrow-alt-circle-up"></i>
            </span>
            <span>Upgrade Plan</span>
          </span></a
        >
      </div>
    </div>

    <div class="content" v-if="user.subType == 'monthly'">
      <p>
        Your <b>monthly</b> subscription renews on <b>{{ subEndPretty }}</b
        >.
      </p>
    </div>

    <div class="content" v-if="user.subType == 'yearly'">
      <p>
        Your <b>yearly</b> subscription renews on <b>{{ subEndPretty }}</b
        >.
      </p>
    </div>

    <div class="content" v-if="user.subType == 'admin'">
      <p>Welcome, admin.</p>
    </div>

    <div class="content" v-if="user.subType == 'cancelled'">
      <p>
        Your <b>Pro Unlimited</b> subscription is
        <b class="has-text-danger">cancelled</b> and will end on <b>{{ subEndPretty }}</b
        >.
      </p>
      <div class="buttons">
        <button class="button is-link" @click="billing()">Reactivate Subscription</button>
      </div>
    </div>

    <div class="content" v-if="user.subType == 'expired'">
      <p>
        Your subscription has <b class="has-text-danger">expired</b>, but we'd love to
        have you back!
      </p>
      <div class="buttons">
        <div class="buttons">
          <a class="button is-success" @click="upgrade()">
            <span class="icon-text">
              <span class="icon">
                <i class="fas fa-arrow-alt-circle-up"></i>
              </span>
              <span>Upgrade Plan</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { api } from '@/modules/util'

export default {
  name: 'AccountStatus',
  props: ['user'],
  data() {
    return {
      modal: '',
      processing: false,
      // switchError: '',
      // reactivateError: '',
    }
  },

  computed: {
    subEndPretty() {
      return dayjs(this.user.subEnd).format('MMMM D, YYYY')
    },
  },

  methods: {
    async upgrade() {
      try {
        const res = await api.get('/users/upgrade')
        window.location.href = res.data.url
      } catch (err) {
        // handle error
      }
    },
    // async switchToYearly() {
    //   try {
    //     this.processing = true
    //     await api.post('/users/switch-to-yearly')
    //     this.processing = false
    //     this.modal = ''
    //     this.$emit('init')
    //   } catch (err) {
    //     this.processing = false
    //     this.switchError = parseError(err)
    //   }
    // },
    // async reactivate() {
    //   try {
    //     this.processing = true
    //     await api.post('/users/reactivate-subscription')
    //     this.processing = false
    //     this.modal = ''
    //     this.$emit('init')
    //   } catch (err) {
    //     this.processing = false
    //     this.reactivateError = parseError(err)
    //   }
    // },
  },
}
</script>
