import Vue from 'vue'
import VueRouter from 'vue-router'

// Eager loaded
import Home from '@/views/Home.vue'
import VatLookup from '@/views/VatLookup.vue'
import Pricing from '@/views/Pricing.vue'
import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'

// main & parent views - lazy loaded
const Contact = () => import('@/views/Contact.vue')
const ResetPassword = () => import('@/views/ResetPassword.vue')
const NewPassword = () => import('@/views/NewPassword.vue')

const FormW8ben = () => import('@/views/FormW8ben.vue')
const FormW9 = () => import('@/views/FormW9.vue')
const Preview = () => import('@/views/Preview.vue')

// parent views
const DocsParent = () => import('@/views/DocsParent.vue')
const AppParent = () => import('@/views/AppParent.vue')

// app views - lazy loaded
const Inbox = () => import('@/app-views/Inbox.vue')
const RequestForms = () => import('@/app-views/RequestForms.vue')
const Developer = () => import('@/app-views/Developer.vue')
const Form = () => import('@/app-views/Form.vue')
const Settings = () => import('@/app-views/Settings.vue')
const Billing = () => import('@/app-views/Billing.vue')
const Admin = () => import('@/app-views/Admin.vue')

// doc views - lazy loaded
const GettingStarted = () => import('@/doc-views/GettingStarted.vue')
const TermsOfService = () => import('@/doc-views/TermsOfService.vue')
const PrivacyPolicy = () => import('@/doc-views/PrivacyPolicy.vue')
const Validation = () => import('@/doc-views/Validation.vue')
const ValidationV1 = () => import('@/doc-views/ValidationV1.vue')
const Lookup = () => import('@/doc-views/Lookup.vue')
// const FormRequests = () => import('@/doc-views/FormRequests.vue')
// const Webhooks = () => import('@/doc-views/Webhooks.vue')
// const DownloadPdf = () => import('@/doc-views/DownloadPdf.vue')
// const ListForms = () => import('@/doc-views/ListForms.vue')
// const FormObject = () => import('@/doc-views/FormObject.vue')
// const W9Object = () => import('@/doc-views/W9Object.vue')
// const W8benObject = () => import('@/doc-views/W8benObject.vue')
const Authorization = () => import('@/doc-views/Authorization.vue')
const Faq = () => import('@/doc-views/Faq.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Validate Tax ID Numbers' },
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    meta: { title: 'Pricing' },
  },
  {
    path: '/tax-id-validation',
    name: 'TaxIdValidation',
    component: Home,
    meta: { title: 'Validate Tax ID Numbers' },
  },
  {
    path: '/vat-lookup',
    name: 'VatLookup',
    component: VatLookup,
    meta: { title: 'VAT Lookup API' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact Us' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Log In' },
  },
  {
    path: '/signup/:plan?',
    name: 'Signup',
    component: Signup,
    meta: { title: 'Sign Up' },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { title: 'Reset Your Password' },
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: NewPassword,
    meta: { title: 'Create a New Password' },
  },
  {
    path: '/app',
    name: 'App',
    component: AppParent,
    redirect: { name: 'Settings' },
    children: [
      {
        name: 'Developer',
        path: 'developer',
        component: Developer,
      },
      {
        name: 'Inbox',
        path: 'inbox',
        component: Inbox,
        meta: { title: 'Tax Form Inbox' },
      },
      {
        name: 'Form',
        path: 'form/:id',
        component: Form,
        meta: { title: 'View Form' },
      },
      {
        name: 'Settings',
        path: 'settings',
        component: Settings,
      },
      {
        name: 'Billing',
        path: 'billing',
        component: Billing,
        meta: { title: 'Billing Information' },
      },
      {
        name: 'RequestForms',
        path: 'request',
        component: RequestForms,
        meta: { title: 'Request Tax Forms' },
      },
      {
        name: 'Admin',
        path: 'admin',
        component: Admin,
        meta: { title: 'Admin' },
      },
    ],
  },
  {
    path: '/docs',
    name: 'Docs',
    component: DocsParent,
    redirect: { name: 'Authorization' },
    children: [
      {
        name: 'GettingStarted',
        path: 'getting-started',
        component: GettingStarted,
        meta: { title: 'Getting Started with Tax ID Validation' },
      },
      {
        name: 'Authorization',
        path: 'authorization',
        component: Authorization,
        meta: { title: 'Authorization' },
      },
      {
        name: 'TermsOfService',
        path: 'terms-of-service',
        component: TermsOfService,
        meta: { title: 'Terms of Service' },
      },
      {
        name: 'PrivacyPolicy',
        path: 'privacy-policy',
        component: PrivacyPolicy,
        meta: { title: 'Privacy Policy' },
      },
      {
        name: 'Validation',
        path: 'tax-id-validation',
        component: Validation,
        meta: { title: 'Validate Tax IDs for Over 100 Countries' },
      },
      {
        name: 'ValidationV1',
        path: 'api-reference/v1',
        component: ValidationV1,
        meta: { title: 'Validate Tax IDs' },
      },
      {
        name: 'Lookup',
        path: 'vat-lookup',
        component: Lookup,
        meta: { title: 'VAT Lookup API for EU and UK' },
      },
      {
        name: 'FormRequests',
        path: 'form-requests',
        beforeEnter: () => window.location.replace('https://nextform.app/docs/api-quickstart'),
      },
      {
        name: 'Webhooks',
        path: 'webhooks',
        beforeEnter: () => window.location.replace('https://nextform.app/docs/webhooks'),
      },
      {
        name: 'ListForms',
        path: 'list-forms',
        beforeEnter: () => window.location.replace('https://nextform.app/docs/list-forms'),
      },
      {
        name: 'DownloadPdf',
        path: 'download-pdf',
        beforeEnter: () => window.location.replace('https://nextform.app/docs/pdf-request'),
      },
      {
        name: 'FormObject',
        path: 'form-object',
        beforeEnter: () => window.location.replace('https://nextform.app/docs/form-object'),
      },
      {
        name: 'W9Object',
        path: 'w9-object',
        beforeEnter: () => window.location.replace('https://nextform.app/docs/w9-oct-2018'),
      },
      {
        name: 'W8benObject',
        path: 'w8ben-object',
        beforeEnter: () => window.location.replace('https://nextform.app/docs/w8ben-oct-2021'),
      },
      {
        name: 'Faq',
        path: 'faq',
        component: Faq,
        meta: { title: 'Frequently Asked Questions' },
      },
    ],
  },
  {
    path: '/form/w9',
    name: 'FormW9',
    component: FormW9,
    meta: { title: 'Prepare and Send Form W-9' },
  },
  {
    path: '/form/w8ben',
    name: 'FormW8ben',
    component: FormW8ben,
    meta: { title: 'Prepare and Send Form W-8BEN' },
  },
  {
    path: '/preview/:token',
    name: 'Preview',
    component: Preview,
    meta: { title: 'View Tax Form' },
  },

  // redirects
  {
    path: '*',
    redirect: { name: 'Home' },
  },
  {
    path: '/docs/interactive-w-8ben-guide',
    redirect: { name: 'FormW8ben' },
  },
  {
    path: '/validate-tax-id-numbers',
    redirect: { name: 'Home' },
  },
  {
    path: '/send/w9',
    redirect: { name: 'FormW9' },
  },
  {
    path: '/send/w8ben',
    redirect: { name: 'FormW8ben' },
  },
  {
    path: '/validate',
    redirect: { name: 'Home' },
  },
  {
    path: '/tax-form-automation',
    redirect: { name: 'Home' },
  },
  {
    path: '/app/developer-tools',
    redirect: { name: 'Developer' },
  },
  {
    path: '/app/dashboard',
    redirect: { name: 'Settings' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach((to) => {
  let title = 'Tax ID Pro'
  if (to.meta.title) title = to.meta.title + ' - Tax ID Pro'
  document.title = title
})

export default router
