<template>
  <div>
    <section class="section has-background-white-bis is-medium">
      <div class="container has-text-centered" v-if="!$route.params.plan">
        <div>
          <div class="montserrat has-text-link" style="margin-bottom: 25px">
            30 DAY FREE TRIAL
          </div>
          <h1 class="title">Get Started for Free</h1>
          <h4 class="subtitle is-6">
            Give it a try with 100 tax ID validations and 10 VAT lookups.
          </h4>
        </div>
      </div>
      <div class="container has-text-centered" v-if="$route.params.plan == 'monthly'">
        <div>
          <div class="montserrat has-text-link" style="margin-bottom: 25px">MONTHLY</div>
          <h1 class="title">Create an Account</h1>
          <h4 class="subtitle is-6">
            Create an account, then we'll set up your subscription
          </h4>
        </div>
      </div>
      <div class="container has-text-centered" v-if="$route.params.plan == 'yearly'">
        <div>
          <div class="montserrat has-text-link" style="margin-bottom: 25px">YEARLY</div>
          <h1 class="title">Create an Account</h1>
          <h4 class="subtitle is-6">
            Create an account, then we'll set up your subscription
          </h4>
        </div>
      </div>
      <br /><br />
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5">
            <div class="box">
              <SignupForm @success="success" />
            </div>
            <p class="has-text-centered">
              <router-link :to="{ name: 'Login' }"> Need to log in instead? </router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Signup',
  props: ['user'],
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user.id) {
          if (this.$route.query.redirect) {
            this.$router.push({ name: this.$route.query.redirect })
          } else if (this.$route.params.plan == 'monthly') {
            this.$router.push({ name: 'Upgrade', params: { plan: 'monthly' } })
          } else if (this.$route.params.plan == 'yearly') {
            this.$router.push({ name: 'Upgrade', params: { plan: 'yearly' } })
          } else {
            this.$router.push({ name: 'Settings' })
          }
        }
      },
    },
  },
  mounted() {
    window.location.href = process.env.VUE_APP_V3 + '/signup'
  },
  methods: {
    success() {
      this.$emit('init')
    },
  },
}
</script>
