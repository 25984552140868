<template>
  <div>
    <section class="hero mt-5">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-variable is-6">
            <div class="column is-6">
              <div
                class="box has-background-dark has-text-light"
                style="font-family: monospace"
              >
                <div class="code-label">REQUEST</div>
                <span class="has-text-success">GET</span>
                https://api.taxid.pro/validate<br />
                <div style="padding-left: 18px">
                  ? tin = <span class="has-text-warning">36574261</span><br />
                  &amp; country = <span class="has-text-warning">ca</span><br />
                  &amp; type = <span class="has-text-warning">individual</span><br />
                </div>
                <br />
                <div class="code-label mt-3">RESPONSE</div>
                {<br />
                <div style="padding-left: 18px">
                  "valid": <span class="has-text-danger">false</span>,<br />
                  "message": "Invalid Social Insurance Number."
                </div>
                }
              </div>
            </div>
            <div class="column is-6">
              <h2 class="title montserrat">Deploy Validation Anywhere</h2>
              <div class="content is-size-5">
                <h4></h4>
                <p>
                  You can implement our API in minutes and validate over 200 tax ID
                  formats for over 100 countries. Tax ID Pro is the best solution for any
                  form with tax ID input.
                </p>
                <p>
                  Intelligent real-time feedback provides guidance for invalid numbers.
                  The response can include the name of the format and the expected number
                  of digits or characters.
                </p>
                <p>
                  Read our
                  <router-link :to="{ name: 'GettingStarted' }">
                    getting started
                  </router-link>
                  guide to learn more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section mb-6">
      <div class="container">
        <div class="columns is-variable is-8">
          <div class="column">
            <div class="content">
              <h4>
                <i class="fas fa-rocket has-text-danger" style="margin-right: 5px"></i>
                Fast
              </h4>
              <p>
                Validations are returned in milliseconds, fast enough to display
                validation results to your customers in real time as they type their tax
                ID number into a form field.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <h4>
                <i
                  class="fas fa-check-circle has-text-success"
                  style="margin-right: 5px"
                ></i>
                Comprehensive
              </h4>
              <p>
                With over 200 tax ID number formats supported for over 100 countries, Tax
                ID Pro provides the validation you need to serve a global marketplace.
              </p>
            </div>
          </div>

          <div class="column">
            <div class="content">
              <h4>
                <i class="fas fa-lock has-text-link" style="margin-right: 5px"></i>
                Secure
              </h4>
              <p>
                Data is transmitted using secure AES-256 encryption. Our application is
                built following the OWASP Top 10 awareness principles to minimize security
                risks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section has-background-info">
      <div class="container">
        <div class="level mt-5 mb-5">
          <div class="level-item has-text-centered has-text-white">
            <span class="is-size-5">Sign Up for a Free Trial</span>
            <router-link
              :to="{ name: 'Signup' }"
              class="button is-rounded is-info is-inverted"
              style="margin-left: 20px"
            >
              Get Started
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.table tr td {
  border: none;
}

.property-labels {
  width: 15%;
  min-width: 7rem;
}
</style>

<script>
import selectCountryDemo from '@/modules/selectCountryDemo.json'
import { select, field } from '@/modules/util'
// const randomDemo = getRandomDemo()
import demoList from '@/modules/demoList.json'

export default {
  name: 'Validate',
  props: ['user'],
  data() {
    return {
      staleJSON: false,
      retryValidation: 0,
      demoList,
      selectCountryDemo,
      type: 'individual',
      country: select(),
      tin: field(),
      processing: false,
      results: { valid: null, message: '' },
    }
  },
  watch: {
    type() {
      this.retryValidation++
    },
    'tin.processing': function () {
      if (this.tin.processing) {
        this.staleJSON = true
      } else {
        this.staleJSON = false
        this.results.message = this.tin.error
        if (this.results.message == 'Format not available.') {
          this.results.valid = null
        } else {
          this.results.valid = this.tin.valid
        }
      }
    },
  },

  methods: {
    randomizeDemo() {
      const demo = this.demoList[Math.floor(Math.random() * demoList.length)]
      this.type = demo.type
      this.country.value = demo.country
      this.tin.value = demo.tin
      this.retryValidation++
    },
  },
  mounted() {
    window.scrollTo(0, 0)
    this.randomizeDemo()
  },
}
</script>
