<template>
  <div>
    <section class="section has-background-white-ter" v-if="whitelabel"></section>
    <section class="section has-background-white-ter mb-6" v-else>
      <div class="container" style="line-height: 1.9rem">
        <div class="columns">
          <div class="column is-2">
            <span class="icon-text">
              <span class="icon">
                <i class="fas fa-layer-group"></i>
              </span> </span
            ><br />
            <h5 class="has-text-weight-bold">Services</h5>
            <router-link :to="{ name: 'Home' }">Tax ID Validation</router-link><br />
            <router-link :to="{ name: 'VatLookup' }"> VAT Lookup </router-link><br />
            <a href="https://nextform.app">Nextform</a>
          </div>

          <div class="column is-2">
            <span class="icon-text">
              <span class="icon">
                <i class="fas fa-book"></i>
              </span> </span
            ><br />
            <h5 class="has-text-weight-bold">Documentation</h5>
            <router-link :to="{ name: 'GettingStarted' }">Getting Started</router-link
            ><br />
            <router-link :to="{ name: 'Validation' }">Validation API</router-link><br />
            <router-link :to="{ name: 'Lookup' }">VAT Lookup API</router-link><br />
            <router-link :to="{ name: 'Authorization' }">Authorization</router-link><br />
          </div>

          <div class="column is-2">
            <span class="icon-text">
              <span class="icon">
                <i class="fas fa-columns"></i>
              </span> </span
            ><br />
            <h5 class="has-text-weight-bold">Plans</h5>
            <router-link :to="{ name: 'Pricing' }">Plan Pricing</router-link><br />
            <router-link :to="{ name: 'Signup' }">Trial Account</router-link><br />
            <router-link :to="{ name: 'TermsOfService' }">Terms of Service</router-link
            ><br />
            <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
            <br />
          </div>

          <div class="column is-2">
            <span class="icon-text">
              <span class="icon">
                <i class="fas fa-plug"></i>
              </span> </span
            ><br />
            <h5 class="has-text-weight-bold">Connect With Us</h5>
            <a href="https://twitter.com/thetaxidpro">Twitter</a><br />
            <a href="https://facebook.com/thetaxidpro">Facebook</a><br />
            <a href="https://www.linkedin.com/company/tax-id-pro">LinkedIn</a>
          </div>

          <div class="column is-4 has-text-right">Copyright {{ year }} Tax ID Pro</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    whitelabel() {
      return (
        (this.$route.name == 'FormW9' && this.$route.query.token) ||
        (this.$route.name == 'FormW8ben' && this.$route.query.token)
      )
    },
  },
}
</script>
