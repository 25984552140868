<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <Field
          v-bind.sync="form.email"
          :label="'Email'"
          :func="'email'"
          :path="'/user/email/new'"
        />
      </div>
      <div class="column is-12">
        <Field
          v-bind.sync="form.password"
          :label="'Password'"
          :func="'password'"
          :path="'/user/password'"
          :type="'password'"
          @submit="submit"
        />
      </div>
      <div class="column is-12">
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="form.tos" style="margin-right: 5px" />
              I agree to the
              <a href="/docs/terms-of-service/" target="_blank">Terms of Service</a>.
            </label>
          </div>
        </div>
      </div>

      <!-- TODO require a pin to create the account? -->

      <div class="column is-12">
        <div class="buttons">
          <button
            class="button is-link"
            :class="{ 'is-loading': processing }"
            @click="submit"
            :disabled="!allValid"
          >
            Create Account
          </button>
        </div>
      </div>
      <FlashMessage :flash="flash" />
    </div>
  </div>
</template>

<script>
import { api, field } from '@/modules/util'

export default {
  name: 'Signup',
  data() {
    return {
      form: {
        email: field(),
        password: field(),
        tos: false,
      },
      processing: false,
      flash: [],
    }
  },
  computed: {
    allValid() {
      return this.form.email.valid && this.form.password.valid && this.form.tos
    },
  },
  methods: {
    async submit() {
      try {
        this.processing = true
        const res = await api.post('/users/signup', {
          form: this.form,
          plan: this.$route.params.plan,
        })
        // this.$emit('success')
        window.location.href = res.data
      } catch (err) {
        this.processing = false
        this.flash.push({ msg: err, type: 'error' })
      }
    },
  },
}
</script>
