<template>
  <div>
    <div class="box">
      <h6 class="title is-6">
        Webhook Settings (<router-link :to="{ name: 'Webhooks' }"
          >Documentation</router-link
        >)
      </h6>

      <label for="" class="label is-small"
        >Signing secret

        <div class="tooltip" style="margin-left: 2px">
          <i class="fas fa-question-circle has-text-grey-lighter"></i>
          <div class="top">
            A signing secret will automatically be generated when you enter a webhook URL
            below. Use the signing secret to verify that events were sent by Tax ID Pro,
            and not by a third party. Read the documentation for more details.
            <i></i>
          </div>
        </div>
      </label>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input
            type="text"
            readonly="readonly"
            class="input"
            :value="webhookSecret"
            :disabled="!webhookSecret"
            style="font-family: monospace"
          />
        </p>
        <p class="control">
          <a
            class="button is-warning"
            @click="showConfirmSecret = true"
            :disabled="false"
          >
            Generate New
          </a>
        </p>
      </div>

      <Field
        :label="'Webhook URL'"
        :placeholder="'https://example.com/webhook'"
        v-bind.sync="webhookUrl"
        :func="'url'"
        :required="false"
        :tooltip="'The URL to receive webhook events. Save the URL before attempting to send tests.'"
      />

      <div class="buttons">
        <div
          class="button is-link"
          @click="saveWebhookUrl()"
          :class="{ 'is-loading': processingSave }"
          :disabled="!webhookUrl.valid"
        >
          Save Webhook URL
        </div>
        <div
          class="button"
          @click="sendTest('w9')"
          :class="{ 'is-loading': processingTest }"
          :disabled="!webhookUrl.value || !webhookUrl.valid"
        >
          Send Test W-9
        </div>
        <div
          class="button"
          @click="sendTest('w8ben')"
          :class="{ 'is-loading': processingTest }"
          :disabled="!webhookUrl.value || !webhookUrl.valid"
        >
          Send Test W-8BEN
        </div>
      </div>
    </div>

    <!-- generate new API key modal -->
    <div class="modal is-active" v-if="showConfirmSecret">
      <div class="modal-background" @click="showConfirmSecret = false"></div>
      <div class="modal-content">
        <div class="box">
          <h5 class="title is-5">Confirm New Signing Secret</h5>
          <div class="content">
            <p>
              <b>Warning:</b> If your signing secret is currently in use, you will need to
              immediately replace your signing secret. Once you click "Generate New
              Signing Secret" your old signing secret will stop working.
            </p>
          </div>
          <div class="buttons">
            <button
              class="button is-warning"
              :class="{ 'is-loading': processingSecret }"
              @click="newSigningSecret()"
            >
              Generate New Signing Secret
            </button>
            <button
              class="button"
              @click="showConfirmSecret = false"
              :disabled="processingSecret"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
    <FlashMessage :flash="flash" />
  </div>
</template>

<script>
import { api, field } from '@/modules/util'
export default {
  name: 'WebhookSettings',
  data() {
    return {
      webhookUrl: field(),
      webhookSecret: '',
      processingSave: false,
      showConfirmSecret: false,
      processingSecret: false,
      processingTest: false,
      flash: [],
    }
  },
  methods: {
    async init() {
      try {
        const res = await api.get('/users/webhooks')
        this.webhookUrl.value = res.data.webhookUrl
        this.webhookSecret = res.data.webhookSecret
      } catch (err) {
        // TODO
      }
    },
    async saveWebhookUrl() {
      try {
        this.processingSave = true
        const res = await api.post('/users/webhookUrl', {
          webhookUrl: this.webhookUrl.value,
        })
        this.webhookUrl.value = res.data.webhookUrl
        this.webhookSecret = res.data.webhookSecret
        this.processingSave = false
        this.flash.push({ msg: 'Webhook URL saved.' })
      } catch (err) {
        // TODO
        this.processingSave = false
      }
    },
    async sendTest(formNumber) {
      try {
        this.processingTest = true
        await api.get(`/forms/${formNumber}/webhookTest`)
        this.processingTest = false
        this.flash.push({ msg: 'Webhook test sent!' })
      } catch (err) {
        this.flash.push({ msg: 'Webhook test failed!', type: 'error' })
        this.processingTest = false
      }
    },
    async newSigningSecret() {
      try {
        this.processingSecret = true
        const res = await api.post('/users/webhookSecret')
        this.webhookSecret = res.data.webhookSecret
        this.processingSecret = false
        this.showConfirmSecret = false
        this.flash.push({ msg: 'Signing secret updated.' })
      } catch (err) {
        // TODO
        this.processingSecret = false
        this.showConfirmSecret = false
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
