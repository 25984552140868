<template>
  <div>
    <!-- <Maintenance /> -->
    <Loading :loading="!user" />
    <MainNav v-if="user" :user="user" />
    <router-view v-if="user" :user="user" @init="init" />
    <MainFooter v-if="user" />
  </div>
</template>

<style lang="scss">
$body-background-color: #f5f5f5;
$family-sans-serif: open-sans, sans-serif;
$primary: #00d4ff;
$link: #4f63e5;
$info: #002c59;

@import url('https://use.typekit.net/esn4rtv.css');
@import '~bulma/bulma.sass';
h1,
.montserrat {
  font-family: montserrat, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.label {
  font-weight: 600;
}

table.table thead th {
  border-width: 0 0 1px;
  font-size: 0.75rem;
  font-weight: 600;
}
.menu-list {
  font-size: 0.9rem;
}

/* http://www.menucool.com/tooltip/css-tooltip */

.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
  cursor: default;
}

.tooltip .top {
  min-width: 300px;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 15px 20px;
  color: #ffffff;
  background-color: #444444;
  font-size: 0.75rem;
  border-radius: 4px;
  position: absolute;
  z-index: 9;
  box-sizing: border-box;
  display: none;
}

.tooltip:hover i {
  color: hsl(0, 0%, 29%) !important;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 10px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #444444;
}

.code-label {
  text-transform: uppercase;
  color: #888;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.param {
  font-family: monospace;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.param-note {
  font-family: open-sans, sans-serif;
  font-weight: bold;
  color: #aaa;
  font-size: 0.75rem;
}
.value {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 0.75rem;
  padding: 2px 4px 1px;
  font-family: monospace;
  font-weight: bold;
}
</style>

<script>
import { api } from '@/modules/util'

export default {
  name: 'App',
  data() {
    return {
      user: null,
    }
  },
  methods: {
    async init() {
      try {
        // this.user = null
        const res = await api.get('/users/session')
        if (!res.data.user) throw 'No user.'
        this.user = res.data.user
      } catch (err) {
        this.user = {
          id: 0,
          email: '',
          firstName: '',
          lastName: '',
          name: '',
          businessName: '',
          subType: 'expired',
          subEnd: new Date(0),
          freeRequests: 0,
          // isActive: false,
          // goal: 'upgrade'
          // isPro: false
          // canUpgrade: true
        }
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
