<template>
  <div class="maintenance">
    Performing system upgrades. We will be back shortly! <br> <br>
    Tax ID Pro
  </div>
</template>

<style scoped>
.maintenance {
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #002c59;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 41;
}

</style>

<script>
export default {
  name: 'Maintenance',
}
</script>