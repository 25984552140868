<template>
  <div class="field">
    <label class="label is-small" style="font-weight: 600">
      {{ label }}
      <div class="tooltip" style="margin-left: 2px" v-if="tooltip">
        <i class="fas fa-question-circle has-text-grey-lighter"></i>
        <div class="top">
          {{ tooltip }}
          <i></i>
        </div>
      </div>
    </label>
    <div
      class="control has-icons-right"
      :class="{
        'signature-control': signature,
        'is-loading': processing,
        'is-large': signature,
      }"
    >
      <input
        class="input"
        :class="{
          'is-danger': error,
          'is-success': green && valid,
          signature: signature,
          large: large,
          'is-large': signature || large,
        }"
        :type="type"
        :placeholder="placeholder || label"
        :disabled="disabled"
        v-model="local"
        @input="handleInput"
        @keyup.enter="submit"
      />
      <!-- <input 
      class="input" 
      :class="{ 'is-danger': error }"
      :type="type"
      :placeholder="placeholder || label" 
      :disabled="disabled"
      v-model="local"
      @keyup.enter="submit"
      @input="handleInput"
      @blur="handleBlur"> -->
      <span
        v-if="valid && !processing"
        class="icon is-small is-right is-success"
        :style="{ color: green ? '#48c774' : '#dbdbdb;' }"
      >
        <i class="fas fa-check fa-xs"></i>
      </span>
    </div>
    <p v-if="error" class="help is-danger">
      {{ error }}
    </p>
  </div>
</template>

<style scoped>
@import url('https://use.typekit.net/rnr3caz.css');

/* http://www.menucool.com/tooltip/css-tooltip */
/* 
.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltip .top {
  min-width: 300px;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 15px 20px;
  color: #ffffff;
  background-color: #444444;
  font-size: 0.75rem;
  border-radius: 4px;
  position: absolute;
  z-index: 9;
  box-sizing: border-box;
  display: none;
}

.tooltip:hover i {
  color: hsl(0, 0%, 29%) !important;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 10px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #444444;
} */

.signature {
  font-family: professor, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-indent: 10px;
  padding: 5px 80px 5px 7px !important;
  height: 60px;
  font-size: 2rem;
}

.big {
  /* padding: 5px 80px 5px 7px !important; */
  /* height: 60px; */
  font-size: 2rem;
}
</style>

<script>
import { api, validate } from '@/modules/util'

export default {
  name: 'Field',
  props: {
    // field: Object,
    processing: { type: Boolean, default: false },
    retry: { type: Number, default: 0 },
    ms: { type: Number, default: 400 },
    green: { type: Boolean, default: false },
    value: String,
    valid: Boolean,
    error: String,
    label: String,
    tooltip: String,
    placeholder: String,
    type: { type: String, default: 'text' },
    required: { default: 'This field is required.' },
    func: String,
    path: String,
    meta: Object,
    signature: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      local: '',
      timeout: null,
      // processing: false,
    }
  },
  watch: {
    value() {
      this.local = this.value
    },
    retry() {
      if (this.local) {
        this.handleInput()
      }
    },
  },
  methods: {
    init() {
      if (this.value) {
        this.local = this.value
        this.validateValue()
      } else if (!this.required) {
        this.$emit('update:valid', true)
      }
    },
    submit() {
      if (this.valid && !this.processing) this.$emit('submit')
    },
    handleInput() {
      this.$emit('update:processing', true)
      this.$emit('update:value', this.local)
      this.$emit('update:valid', false)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.validateValue, this.ms)
    },
    async validateValue() {
      // handle empty field that isn't required
      if (!this.local && !this.required) {
        this.$emit('update:error', '')
        this.$emit('update:valid', true)
        this.$emit('update:processing', false)
        return
      }

      // begin field validation
      let error = ''
      if (!this.local && this.required) error = this.required
      if (!error && this.func) {
        error = validate[this.func]({ value: this.local, meta: this.meta })
      }
      if (!error && this.path) {
        const res = await api.post(`/fields${this.path}`, {
          value: this.local,
          meta: this.meta,
        })
        error = res.data
        // console.log('called')
      }
      if (error) {
        this.$emit('update:error', error)
        this.$emit('update:valid', false)
      } else {
        this.$emit('update:error', '')
        this.$emit('update:valid', true)
      }
      this.$emit('update:processing', false)
    },
  },
  mounted() {
    this.init()
  },
}
</script>