<template>
  <div>
    <section class="section has-background-white-bis">
      <div class="container has-text-centered">
        <h1 class="title" style="margin-bottom: 30px">Log In</h1>
      </div>

      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5">
            <div class="box">
              <LoginForm @success="success" />
            </div>
            <p class="has-text-centered">
              <router-link :to="{ name: 'Signup' }">
                Need to sign up instead?
              </router-link>
            </p>
          </div>
        </div>
      </div>

      <br /><br /><br />
    </section>
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: ['user'],
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user.id) {
          if (this.$route.query.redirect) {
            this.$router.push({ name: this.$route.query.redirect })
          } else {
            this.$router.push({ name: 'Settings' })
          }
        }
      },
    },
  },
  methods: {
    success() {
      this.$emit('init')
    },
  },
}
</script>
