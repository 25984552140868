<template>
  <div>
    <div v-if="whitelabel"></div>

    <nav class="navbar is-info" role="navigation" aria-label="main navigation" v-else>
      <div class="navbar-brand">
        <router-link
          class="navbar-item montserrat"
          style="font-size: 1.1em"
          :to="{ name: 'Home' }"
        >
          <!-- <i class="fas fa-id-card"></i>  -->
          <img src="../assets/logo.png" style="margin-right: 10px" />
          Tax ID Pro
        </router-link>

        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          :class="{ 'is-active': mobileNav }"
          @click="toggleMobileNav"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': mobileNav }">
        <div class="navbar-end">
          <div class="navbar-item has-dropdown is-hoverable" v-if="!user.id">
            <a class="navbar-link is-arrowless"> Services </a>
            <div class="navbar-dropdown is-boxed">
              <router-link :to="{ name: 'TaxIdValidation' }" class="navbar-item">
                Tax ID Validation
              </router-link>
              <router-link :to="{ name: 'VatLookup' }" class="navbar-item">
                VAT Lookup
              </router-link>
            </div>
          </div>

          <a
            v-if="user.subType === 'trial'"
            :to="{ name: 'Pricing' }"
            class="navbar-item"
            @click="upgrade()"
          >
            Upgrade
          </a>

          <a v-else-if="!user.id" href="/pricing" class="navbar-item">Pricing</a>

          <router-link :to="{ name: 'GettingStarted' }" class="navbar-item"
            >Docs</router-link
          >


          <router-link :to="{ name: 'Contact' }" class="navbar-item">
            Contact
          </router-link>

          <div class="navbar-item" v-if="!user.id">
            <div class="buttons">
              <router-link
                :to="{ name: 'Login' }"
                class="button is-rounded is-small is-link is-inverted"
              >
                Log In
              </router-link>
              <a href="https://taxid.pro/signup" class="button is-rounded is-small is-link">
                Free Trial
              </a>
            </div>
          </div>

          <div v-if="user.id" class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link is-arrowless">
              <i class="fas fa-user-circle"></i>
            </a>

            <div class="navbar-dropdown is-right is-boxed">
              <router-link :to="{ name: 'Settings' }" class="navbar-item">
                Account Settings
              </router-link>
              <router-link :to="{ name: 'Developer' }" class="navbar-item">
                Developer Tools
              </router-link>
              <hr class="navbar-divider" />
              <a class="navbar-item" @click="logout"> Log Out </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { api } from '@/modules/util'

export default {
  name: 'MainNav',
  data() {
    return {
      mobileNav: false,
    }
  },
  props: ['user'],
  computed: {
    whitelabel() {
      return (
        (this.$route.name == 'FormW9' && this.$route.query.token) ||
        (this.$route.name == 'FormW8ben' && this.$route.query.token)
      )
    },
  },
  methods: {
    async upgrade() {
      try {
        const res = await api.get('/users/upgrade')
        window.location.href = res.data.url
      } catch (err) {
        // handle error
      }
    },
    async logout() {
      try {
        await api.get('/users/logout')
        window.location.href = '/'
      } catch (err) {
        // do nothing
      }
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    },
  },
}
</script>
