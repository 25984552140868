<template>
  <div>
    <div class="box">
      <div class="content" v-if="user.subType == 'expired'">
        <p class="has-text-danger">
          Your subscription has expired and your API key is inactive.
        </p>
      </div>
      <h6 class="title is-6">
        API Settings (<router-link :to="{ name: 'Authorization' }"
          >Documentation</router-link
        >)
      </h6>

      <label for="" class="label is-small">API Key</label>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input
            type="text"
            readonly="readonly"
            class="input"
            :value="user.apiKey"
            :disabled="user.subType == 'expired'"
            style="font-family: monospace"
          />
        </p>
        <p class="control">
          <a
            class="button is-warning"
            @click="showConfirmApiKey = true"
            :disabled="user.subType == 'expired'"
          >
            Generate New
          </a>
        </p>
      </div>

      <label for="" class="label is-small">Version</label>
      <div class="field has-addons">
        <p class="control is-expanded">
          <input
            type="text"
            readonly="readonly"
            class="input"
            :value="`Version ${user.apiVersion}`"
            :disabled="user.subType == 'expired'"
          />
        </p>
        <p class="control" v-if="user.apiVersion == '1'">
          <a
            class="button is-warning"
            @click="showConfirmApiVersion = true"
            :disabled="user.subType == 'expired'"
          >
            Upgrade to Version 2
          </a>
        </p>
      </div>
    </div>

    <!-- generate new API key modal -->
    <div class="modal is-active" v-if="showConfirmApiKey">
      <div class="modal-background" @click="showConfirmApiKey = false"></div>
      <div class="modal-content">
        <div class="box">
          <div class="content">
            <h4>Please Confirm</h4>
            <p>
              Warning: if your API key is actively being used in your software or web
              application, you will need to immediately replace your API key. Once you
              click "Generate New Key" your old API key will stop working.
            </p>
            <div class="buttons">
              <button
                class="button is-warning"
                :class="{ 'is-loading': processingApiKey }"
                @click="generateNewApiKey"
              >
                Generate New API Key
              </button>
              <button
                class="button"
                @click="showConfirmApiKey = false"
                :disabled="processingApiKey"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>

    <!-- API upgrade modal -->
    <div class="modal is-active" v-if="showConfirmApiVersion">
      <div class="modal-background" @click="showConfirmApiVersion = false"></div>
      <div class="modal-content">
        <div class="box">
          <div class="content">
            <h4>Upgrade to API Version 2</h4>
            <p>
              Warning: Once you click "Upgrade to Version 2" the default version for all
              API requests will become version 2.
            </p>
            <p>
              You may also specify version 1 or version 2 in your query by adding a
              <b>version</b> query parameter (<router-link :to="{ name: 'Validation' }"
                >Learn More</router-link
              >).
            </p>
            <div class="buttons">
              <button
                class="button is-warning"
                :class="{ 'is-loading': processingApiVersion }"
                @click="upgradeApiVersion()"
              >
                Upgrade to Version 2
              </button>
              <button
                class="button"
                @click="showConfirmApiVersion = false"
                :disabled="processingApiVersion"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>

    <FlashMessage :flash="flash" />
  </div>
</template>

<script>
import { api } from '@/modules/util'

export default {
  name: 'ApiSettings',
  props: ['user'],
  data() {
    return {
      flash: [],
      showConfirmApiKey: false,
      processingApiKey: false,
      showConfirmApiVersion: false,
      processingApiVersion: false,
    }
  },
  methods: {
    async generateNewApiKey() {
      try {
        this.processingApiKey = true
        await api.get('/users/generate-new-api-key')
        this.processingApiKey = false
        this.showConfirmApiKey = false
        this.$emit('init')
      } catch (err) {
        this.processingApiKey = false
        this.showConfirmApiKey = false
        this.flash.push({ msg: err, type: 'error' })
      }
    },
    async upgradeApiVersion() {
      try {
        this.processingApiVersion = true
        await api.get('/users/upgrade-api-version')
        this.processingApiVersion = false
        this.showConfirmApiVersion = false
        this.$emit('init')
      } catch (err) {
        this.processingApiVersion = false
        this.showConfirmApiVersion = false
        this.flash.push({ msg: err, type: 'error' })
      }
    },
  },
}
</script>
