var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section is-medium has-background-info has-text-white"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title has-text-centered has-text-white"},[_vm._v("Pricing")]),_c('h2',{staticClass:"subtitle has-text-centered has-text-primary"},[_vm._v(" Start with a free trial and upgrade any time ")]),_c('br'),_c('div',{staticClass:"columns is-centered is-variable is-4"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button is-fullwidth is-link",attrs:{"to":{ name: 'Signup' }}},[_vm._v("Sign Up")])],1)]),_vm._m(1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_vm._m(2),_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button is-fullwidth is-link",attrs:{"to":{ name: 'Signup', params: { plan: 'monthly' } }}},[_vm._v("Sign Up")])],1)]),_vm._m(3)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_vm._m(4),_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button is-fullwidth is-link",attrs:{"to":{ name: 'Signup', params: { plan: 'yearly' } }}},[_vm._v("Sign Up")])],1)]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content has-text-centered"},[_c('h6',{staticClass:"has-text-link"},[_c('i',{staticClass:"far fa-handshake"}),_vm._v(" Free Trial")]),_c('div',{staticClass:"mt-6 mb-6"},[_c('h2',[_vm._v("Free")]),_c('p',{staticClass:"details"},[_vm._v(" Try it with 100 validations "),_c('br'),_vm._v("and 10 VAT lookups ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content details"},[_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"far fa-calendar-check"})]),_c('span',[_vm._v(" Access for 30 Days ")])])]),_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"fas fa-id-card"})]),_c('span',[_vm._v(" Limit of 100 Tax ID Validations")])])]),_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"far fa-star"})]),_c('span',[_vm._v(" Limit of 10 VAT Lookups")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content has-text-centered"},[_c('h6',{staticClass:"has-text-link"},[_c('i',{staticClass:"fas fa-briefcase"}),_vm._v(" Monthly")]),_c('div',{staticClass:"mt-6 mb-6"},[_c('h2',[_vm._v("$9.99 "),_c('span',{staticClass:"period"},[_vm._v("/ mo")])]),_c('p',{staticClass:"details"},[_vm._v("Pay month-to-month,"),_c('br'),_vm._v("cancel any time")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content details"},[_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"far fa-calendar-check"})]),_c('span',[_vm._v(" Cancel Anytime ")])])]),_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"fas fa-id-card"})]),_c('span',[_vm._v(" Unlimited Tax ID Validations")])])]),_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"far fa-star"})]),_c('span',[_vm._v(" Unlimited VAT Lookups ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content has-text-centered"},[_c('h6',{staticClass:"has-text-link"},[_c('i',{staticClass:"far fa-building"}),_vm._v(" Yearly")]),_c('div',{staticClass:"mt-6 mb-6"},[_c('h2',[_vm._v("$99.99 "),_c('span',{staticClass:"period"},[_vm._v("/ yr")])]),_c('p',{staticClass:"details"},[_vm._v(" Save 16% with "),_c('br'),_vm._v(" a yearly plan ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content details"},[_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"far fa-calendar-check"})]),_c('span',[_vm._v(" Cancel Anytime ")])])]),_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"fas fa-id-card"})]),_c('span',[_vm._v(" Unlimited Tax ID Validations ")])])]),_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"far fa-star"})]),_c('span',[_vm._v(" Unlimited VAT Lookups ")])])]),_c('p',[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"fas fa-money-bill-wave"})]),_c('span',[_vm._v(" Best Value ")])])])])
}]

export { render, staticRenderFns }