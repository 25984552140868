<template>
  <div>
    <div
      v-if="lastFlash.msg"
      :key="counter"
      class="notification has-text-centered flash-message is-success"
      :class="{ 'is-danger': lastFlash.type == 'error' }"
    >
      <span v-show="lastFlash.type != 'error'"><i class="fas fa-check"></i></span>
      <span v-show="lastFlash.type == 'error'"
        ><i class="fas fa-exclamation-triangle"></i
      ></span>
      <div style="width: 10px; display: inline-block"></div>
      {{ lastFlash.msg }}
    </div>
  </div>
</template>

<style scoped>
@keyframes in {
  0% {
    bottom: -1000px;
  }
  1% {
    bottom: -120px;
  }
  10% {
    bottom: 10px;
  }
  90% {
    bottom: 10px;
  }
  99% {
    bottom: -120px;
  }
  100% {
    bottom: -1000px;
  }
}

.flash-message {
  z-index: 42;
  position: fixed;
  left: 50%;
  width: 380px;
  padding: 1.25rem 1.5rem;
  margin-left: -190px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  animation-name: in;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}
</style>

<script>
export default {
  // TODO use vue transitions instead of CSS animation
  name: 'FlashMessage',
  props: { flash: Array },
  computed: {
    lastFlash() {
      if (!this.flash.length) return {}
      const last = this.flash[this.flash.length - 1]
      let msg = ''
      if (last.msg.response) msg = last.msg.response.data
      else if (last.msg.message) msg = last.msg.message
      else msg = last.msg
      return { msg, type: last.type }
    },
    counter() {
      return this.flash.length
    },
  },
}
</script>
