<template>
  <div>
    <div class="notification is-danger" v-if="error">
      <p class="has-text-centered">Invalid login attempt.</p>
    </div>
    <div class="columns is-multiline">
      <div class="column is-full">
        <Field v-bind.sync="email" :label="'Email'" :func="'email'" />
      </div>
      <div class="column is-full">
        <Field
          v-bind.sync="password"
          :label="'Password'"
          :func="'password'"
          :type="'password'"
          @submit="submit"
        />
      </div>
      <div class="column is-full">
        <div class="buttons">
          <button
            class="button is-link"
            :class="{ 'is-loading': processing }"
            @click="submit"
            :disabled="!allValid"
          >
            Log In
          </button>
          <router-link :to="{ name: 'ResetPassword' }" class="button is-text">
            Forgot password?
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api, field } from '@/modules/util'

export default {
  name: 'Login',
  data() {
    return {
      email: field(),
      password: field(),
      error: '',
      processing: false,
    }
  },

  computed: {
    allValid() {
      return this.email.valid && this.password.valid
    },
  },

  methods: {
    async submit() {
      try {
        this.processing = true
        await api.post('/users/login', {
          email: this.email.value,
          password: this.password.value,
        })
        this.$emit('success')
      } catch (err) {
        this.processing = false
        this.error = err
      }
    },
  },
}
</script>
