import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import global components
import SignupForm from '@/components/SignupForm.vue'
import LoginForm from '@/components/LoginForm.vue'
// import UpgradeForm from '@/components/UpgradeForm.vue'
import Loading from '@/components/Loading.vue'
import Maintenance from '@/components/Maintenance.vue'
import Field from '@/components/Field.vue'
import Select from '@/components/Select.vue'
import FlashMessage from '@/components/FlashMessage.vue'
import AccountStatus from '@/components/AccountStatus.vue'
import ApiSettings from '@/components/ApiSettings.vue'
import WebhookSettings from '@/components/WebhookSettings.vue'
import MainNav from '@/components/MainNav.vue'
import MainFooter from '@/components/MainFooter.vue'

// set global components
Vue.component('SignupForm', SignupForm)
Vue.component('LoginForm', LoginForm)
Vue.component('Loading', Loading)
Vue.component('Maintenance', Maintenance)
Vue.component('Field', Field)
Vue.component('Select', Select)
Vue.component('FlashMessage', FlashMessage)
Vue.component('AccountStatus', AccountStatus)
Vue.component('ApiSettings', ApiSettings)
Vue.component('WebhookSettings', WebhookSettings)
Vue.component('MainNav', MainNav)
Vue.component('MainFooter', MainFooter)

// set global vars and functions
Vue.config.productionTip = false
// Vue.prototype.$parseError = parseError

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')

// document.addEventListener('DOMContentLoaded', function() {
//   root.$mount('#app')
// })
