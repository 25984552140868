<template>
  <div>
    <label class="label is-small" style="font-weight: 600">
      {{ label }}
      <div class="tooltip" style="margin-left: 2px" v-if="tooltip">
        <i class="fas fa-question-circle has-text-grey-lighter"></i>
        <div class="top">
          {{ tooltip }}
          <i></i>
        </div>
      </div>
    </label>
    <div class="select is-fullwidth" :class="{ 'is-danger': error }">
      <select v-model="local" :disabled="disabled" @change="handleChange">
        <option selected value="">
          {{ placeholder || label }}
        </option>
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.name }}
        </option>
      </select>
    </div>
    <p v-if="error" class="help is-danger">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Field',
  props: {
    retry: { type: Number, default: 0 },
    value: String,
    options: Array,
    valid: Boolean,
    error: String,
    label: String,
    tooltip: String,
    placeholder: String,
    required: { default: 'You must make a selection.' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      local: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.local = this.value || ''
      },
    },
    retry() {
      if (this.local) this.handleChange()
    },
  },
  methods: {
    init() {
      if (!this.required) this.$emit('update:valid', true)
    },
    handleChange(e) {
      this.$emit('update:value', this.local)
      if (this.required && !this.local) {
        this.$emit('update:valid', false)
        this.$emit('update:error', this.required)
      } else {
        this.$emit('update:valid', true)
        this.$emit('update:error', '')
      }
      if (e && e.type == 'change') this.$emit('change')
    },
  },
  mounted() {
    this.init()
  },
}
</script>
