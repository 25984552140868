import axios from 'axios'
import dayjs from 'dayjs'
// import demoList from './demoList.json'

const api = axios.create({ baseURL: process.env.VUE_APP_API, withCredentials: true })
const images = 'https://images.taxid.pro/'
const origin = process.env.VUE_APP_ORIGIN

const validate = {
  name(field) {
    if (!/^[^\d]{1,255}$/i.test(field.value)) return 'Invalid name.'
  },
  email(field) {
    if (!/\S+@\S+\.\S{2,}/.test(field.value)) return 'Invalid email.'
  },
  zip(field) {
    if (!/^\d{5}(?:[-\s]?\d{4})?$/.test(field.value)) return 'Invalid zip code.'
  },
  url(field) {
    if (
      !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=].+\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
        field.value
      )
    )
      return 'Invalid URL.'
  },
  factaCode(field) {
    if (!/^[a-m]$/i.test(field.value)) return 'Invalid FACTA code.'
  },
  payeeCode(field) {
    if (!/^[0-9]([0-3])?$/.test(field.value)) return 'Invalid payee code.'
  },
  dateOfBirth(field) {
    const input = dayjs(field.value)
    const earliest = dayjs().subtract(130, 'years')
    const latest = dayjs()
    if (input < earliest) return `Are you really ${latest.diff(input, 'years')} years old?`
    if (input > latest) return 'Welcome, time traveler.'
  },
  signatureDate(field) {
    const input = dayjs(field.value)
    const earliest = dayjs().subtract(7, 'days')
    const latest = dayjs().add(7, 'days')
    if (input < earliest) return 'Hmm, that was quite a while ago.'
    if (input > latest) return 'Welcome, time traveler.'
  },
  // receiverEmail(field) {
  //   if (!/\S+@\S+\.\S{2,}/.test(field.value)) return 'Invalid email.'
  //   if (field.value == field.meta.senderEmail) {
  //     return 'Please use a different email address for the recipient.'
  //   }
  // },
  // address(field) {
  //   if (!/^(?=.*?[a-z])(?=.*?[0-9]).{6,}$/i.test(field.value)) return 'Invalid address.'
  // },
  address(field) {
    if (field.value.length < 3) return 'Invalid address.'
  },
  password(field) {
    if (field.value.length < 6) return 'Password is too short.'
  },
  tin(field) {
    if (field.value.length < 5) return 'Number is too short.'
  },
  ssn(field) {
    if (!/^\d{9}$/.test(field.value)) return 'Invalid social security number.'
  },
  ein(field) {
    if (!/^\d{9}$/.test(field.value)) return 'Invalid employer identification number.'
  },
  pin(field) {
    if (!/^\d{6}$/.test(field.value)) return 'Invalid PIN.'
  },
}

function flattenFields(obj) {
  const flat = {}
  for (const key in obj) {
    if (typeof obj[key] == 'object') {
      flat[key] = obj[key].value
    } else {
      flat[key] = obj[key]
    }
  }
  return flat
}

function parseError(err) {
  if (err.response) return err.response.data
  else if (err.message) return err.message
  else return err
}

function field({ value = '', valid = false, error = '', processing = false, disabled = false } = {}) {
  return { value, valid, error, processing, disabled }
}

function select({ value = '', options = [], valid = false, error = '', disabled = false } = {}) {
  return { value, options, valid, error, disabled }
}

function thousands(s) {
  return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function pause(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export {
  api,
  images,
  origin,
  validate,
  parseError,
  field,
  select,
  flattenFields,
  thousands,
  pause,
  // getRandomDemo,
}
